<template>
  <div class="animal-list-container">
    <h2>Animal List</h2>
    <div class="filters">
      <div class="filter-item">
        <label for="health-status">Health Status:</label>
        <select id="health-status" v-model="selectedHealthStatus" @change="fetchAnimals">
          <option value="ALL">All</option>
          <option value="HEALTHY">Healthy</option>
          <option value="AILING">Sick</option>
          <option value="DECEASED">Dead</option>
        </select>
      </div>
      <div class="filter-item">
        <label for="species">Species:</label>
        <select id="species" v-model="selectedSpecies" @change="fetchAnimals">
          <option value="ALL">All</option>
          <option value="BOVINE">Bovine</option>
          <option value="OVINE">Ovine</option>
          <option value="PORCINE">Porcine</option>
          <option value="CAPRINE">Caprine</option>
          <option value="EQUINE">Equine</option>
        </select>
      </div>
      <div class="filter-item">
        <label for="sex">Sex:</label>
        <select id="sex" v-model="selectedSex" @change="fetchAnimals">
          <option value="ALL">All</option>
          <option value="MALE">Male</option>
          <option value="FEMALE">Female</option>
        </select>
      </div>
      <div class="filter-item">
        <label for="in-gestation">In Gestation:</label>
        <select :disabled="selectedSex !== 'FEMALE'" id="in-gestation" v-model="selectedInGestation" @change="fetchAnimals">
          <option value="ALL">All</option>
          <option value="true">Yes</option>
          <option value="false">No</option>
        </select>
      </div>
      <div class="filter-item search-field">
        <input type="text" v-model="searchQuery" placeholder="Search by EID">
        <button @click="fetchAnimals">Search</button>
      </div>
    </div>
    <div v-if="isLoading" class="loading">Loading...</div>
    <div v-else>
      <table v-if="animals.length > 0" class="animal-table">
        <thead>
        <tr>
          <th>EID</th>
          <th>Species</th>
          <th>Sex</th>
          <th>Date of Birth</th>
          <th>Health Status</th>
          <th>In Gestation</th>
          <th>Has Health Sensor</th>
          <th>Current Weight (KG)</th>
          <th>Is Isolated</th>
          <th>Actions</th>
        </tr>
        </thead>
        <tbody>
        <tr v-for="animal in animals" :key="animal.id">
          <td @click="viewAnimal(animal)">{{ animal.eid }}</td>
          <td @click="viewAnimal(animal)">{{ animal.animalSpecies }}</td>
          <td @click="viewAnimal(animal)">{{ animal.sex }}</td>
          <td @click="viewAnimal(animal)">{{ new Date(animal.ModisarAnimalDataDump.DateOfBirth).toLocaleDateString() }}</td>
          <td @click="viewAnimal(animal)">{{ getHealthStatus(animal.healthStatus) }}</td>
          <td>{{ animal.isInGestation ? 'Yes' : 'No' }}</td>
          <td>{{ animal.sensorId ? 'Yes' : 'No' }}</td>
          <td>{{ animal.weights && animal.weights.length > 0 ? animal.weights[animal.weights.length - 1].weight : 'N/A' }}</td>
          <td>{{ animal.isIsolated ? 'Yes' : 'No' }}</td>
          <td>
            <button @click="viewAnimal(animal)">View</button>
          </td>
        </tr>
        </tbody>
      </table>
      <p v-else class="no-animals-message">There are no animals matching the selected filters.</p>
      <div class="pagination">
        <button @click="previousPage" :disabled="currentPage === 1">Previous</button>
        <span>Page {{ currentPage }}</span>
        <button @click="nextPage" :disabled="currentPage * itemsPerPage >= totalDocumentCount">Next</button>
      </div>
    </div>
  </div>
</template>

<script lang="ts">
import {
  DEFAULT_ERROR_MESSAGE_WITH_ENGINEERS,
  EAnimalSortType, EAnimalSpecies, EAnimalSexType,
  EGettersType,
  EHealthStatus,
  EModificationType,
  DEFAULT_LIST_LIMIT,
  ESortOrderType,
  EUserType, EGestationStatus
} from '../globals';
import Vue from 'vue';
import { mapGetters } from 'vuex';

export default Vue.extend({
  data() {
    return {
      isLoading: false,
      selectedHealthStatus: EHealthStatus.ALL,
      selectedSpecies: EAnimalSpecies.ALL,
      selectedSex: EAnimalSexType.ALL,
      selectedInGestation: EGestationStatus.ALL,
      searchQuery: '',
      currentPage: 1,
      totalDocumentCount: 0,
      itemsPerPage: DEFAULT_LIST_LIMIT,
      userType: {
        farmer: EUserType.FARMER,
        admin: EUserType.ADMIN,
        vet: EUserType.VET,
        farmManager: EUserType.FARM_MANAGER
      }
    };
  },
  created: function () {
    this.applyFiltersFromQuery();
    this.fetchAnimals();
  },
  computed: {
    ...mapGetters([
      EGettersType.GET_ANIMALS,
      EGettersType.GET_USER_ROLE
    ])
  },
  methods: {
    fetchAnimals() {
      this.isLoading = true;

      if (this.selectedSex !== EAnimalSexType.FEMALE) {
        this.selectedInGestation = EGestationStatus.ALL;
      }

      if (this.searchQuery.length > 0) {
        this.resetFilters();
      }

      this.$store.dispatch(EModificationType.FETCH_ANIMALS, {
        healthStatus: this.selectedHealthStatus,
        animalSpecies: this.selectedSpecies,
        sex: this.selectedSex,
        isInGestation: this.selectedInGestation,
        eid: this.searchQuery,
        sortBy: EAnimalSortType.DOB,
        sortOrder: ESortOrderType.DESC,
        limit: this.itemsPerPage,
        skip: (this.currentPage - 1) * this.itemsPerPage
      }).then((count) => {
        this.totalDocumentCount = count;
        this.isLoading = false;
        if (this.animals.length === 0) {
          this.currentPage = 1;
        }
      }).catch(e => {
        this.isLoading = false;
        console.log('Show on interface: ', e.message || DEFAULT_ERROR_MESSAGE_WITH_ENGINEERS);
      });
    },
    resetFilters: function () {
      this.selectedHealthStatus = EHealthStatus.ALL;
      this.selectedSpecies = EAnimalSpecies.ALL;
      this.selectedSex = EAnimalSexType.ALL;
      this.selectedInGestation = EGestationStatus.ALL;
    },
    viewAnimal(animal: any) {
      this.$router.push({ name: 'animalView', params: { id: animal.id } });
    },
    shouldShow(listOfUserType: any) {
      return listOfUserType.includes(this.userRole);
    },
    getHealthStatus(healthStatus: string) {
      if (healthStatus === EHealthStatus.DECEASED) {
        return "DEAD";
      } else if ( healthStatus === EHealthStatus.AILING) {
        return "SICK";
      } else {
        return "HEALTHY";
      }
    },
    nextPage() {
      if (this.currentPage * this.itemsPerPage < this.totalDocumentCount) {
        this.currentPage++;
        this.fetchAnimals();
      }
    },
    previousPage() {
      if (this.currentPage > 1) {
        this.currentPage--;
        this.fetchAnimals();
      }
    },
    applyFiltersFromQuery() {
      const { sex, inGestation, healthStatus } = this.$route.query;
      if (healthStatus) this.selectedHealthStatus = healthStatus as EHealthStatus;
      if (sex) this.selectedSex = sex as EAnimalSexType;
      if (inGestation) this.selectedInGestation = inGestation === EGestationStatus.TRUE ? EGestationStatus.TRUE : EGestationStatus.FALSE;
    }
  }
});
</script>

<style scoped>
.animal-list-container {
  max-width: 1200px;
  margin: 0 auto;
  padding: 20px;
}

h2 {
  font-size: 24px;
  font-weight: bold;
  margin-bottom: 20px;
  
}

.add-animal-btn {
  padding: 10px 20px;
  font-size: 16px;
  background-color: #2dce89;
  color: #fff;
  border: none;
  border-radius: 4px;
  cursor: pointer;
  margin-bottom: 20px;
}

.add-animal-btn:hover {
  background-color: #1db674;
}

.filters {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 20px;
}

.filter-item {
  display: flex;
  align-items: center;
}

.filter-item label {
  margin-right: 10px;
}

select, input {
  padding: 8px;
  border-radius: 4px;
  border: 1px solid #ccc;
}

.search-field input {
  margin-right: 5px;
}

button {
  background-color: #007bff;
  color: white;
  border: none;
  padding: 8px 12px;
  border-radius: 4px;
  cursor: pointer;
}

button:hover {
  background-color: #0056b3;
}

.animal-table {
  width: 100%;
  border-collapse: collapse;
}

.animal-table th, .animal-table td {
  padding: 15px;
  text-align: left;
  border-bottom: 2px solid #666666;
}

.animal-table th {
  background-color: #f4f4f4;
}

.loading {
  
  font-size: 18px;
  text-align: center;
  margin: 20px 0;
}

.no-animals-message {
  
  margin: 100px 0;
  font-size: 18px;
  text-align: center;
  color: #888;
}

.pagination {
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: 20px;
}

.pagination button {
  margin: 0 5px;
}
</style>
