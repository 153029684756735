import Vue from 'vue';
import VueRouter, { RouteConfig } from 'vue-router';
import DashboardView from '../views/DashboardView.vue';
import AnimalsView from '../views/AnimalsView.vue';
import MetricsView from '../views/MetricsView.vue';
import HealthAlertsView from '../views/HealthAlertsView.vue';
import ReportsView from '../views/ReportsView.vue';
import SensorsView from '../views/SensorsView.vue';
import VendorsView from '../views/VendorsView.vue';
import UsersView from '../views/UsersView.vue';
import LoginView from '../views/LoginView.vue';
import AddSensorView from '../views/AddSensorView.vue';
import LinkSensorView from '../views/LinkSensorView.vue';
import AddVendorView from '../views/AddVendorView.vue';
import AnimalView from '../views/AnimalView.vue';
import SensorView from '../views/SensorView.vue';
import AddUserView from '../views/AddUserView.vue';
import VendorView from '../views/VendorView.vue';
import UserView from '../views/UserView.vue';
import AddReportView from '../views/AddReportView.vue';
import ForgotPasswordView from '../views/ForgotPasswordView.vue';
import ResetPasswordView from '../views/ResetPasswordView.vue';
import store from '../store';
import {EGettersType, EUserType} from '../globals';
import { getUserRole } from '../util';

Vue.use(VueRouter);

const routes: Array<RouteConfig> = [
  {
    path: '/',
    name: 'dashboard',
    component: DashboardView,
    meta: { requiresAuth: true, allowedRoles: [EUserType.ADMIN, EUserType.VET, EUserType.FARMER, EUserType.FARM_MANAGER] },
    children: [
      {
        path: 'metrics',
        name: 'metrics',
        component: MetricsView,
        meta: { requiresAuth: true, allowedRoles: [EUserType.ADMIN, EUserType.VET, EUserType.FARMER, EUserType.FARM_MANAGER] }
      },
      {
        path: 'sensors/add',
        name: 'sensorAdd',
        component: AddSensorView,
        meta: { requiresAuth: true, allowedRoles: [EUserType.ADMIN] }
      },
      {
        path: 'sensors/:id',
        name: 'sensorView',
        component: SensorView,
        props: true,
        meta: { requiresAuth: true, allowedRoles: [EUserType.ADMIN] }
      },
      {
        path: 'sensors',
        name: 'sensors',
        component: SensorsView,
        meta: { requiresAuth: true, allowedRoles: [EUserType.ADMIN] }
      },
      {
        path: 'vendors/add',
        name: 'vendorAdd',
        component: AddVendorView,
        meta: { requiresAuth: true, allowedRoles: [EUserType.ADMIN] }
      },
      {
        path: 'vendors/:id',
        name: 'vendorView',
        component: VendorView,
        props: true,
        meta: { requiresAuth: true, allowedRoles: [EUserType.ADMIN] }
      },
      {
        path: 'vendors',
        name: 'vendors',
        component: VendorsView,
        meta: { requiresAuth: true, allowedRoles: [EUserType.ADMIN] }
      },
      {
        path: 'users/add',
        name: 'userAdd',
        component: AddUserView,
        meta: { requiresAuth: true, allowedRoles: [EUserType.ADMIN] }
      },
      {
        path: 'users/:id',
        name: 'userView',
        component: UserView,
        props: true,
        meta: { requiresAuth: true, allowedRoles: [EUserType.ADMIN] }
      },
      {
        path: 'users',
        name: 'users',
        component: UsersView,
        meta: { requiresAuth: true, allowedRoles: [EUserType.ADMIN] }
      },
      {
        path: 'health-alerts',
        name: 'healthAlerts',
        component: HealthAlertsView,
        meta: { requiresAuth: true, allowedRoles: [EUserType.ADMIN, EUserType.VET, EUserType.FARMER, EUserType.FARM_MANAGER] }
      },
      {
        path: 'sensor-link/:id',
        name: 'sensorLink',
        component: LinkSensorView,
        props: true,
        meta: { requiresAuth: true, allowedRoles: [EUserType.ADMIN, EUserType.FARMER, EUserType.FARM_MANAGER] }
      },
      {
        path: 'animals',
        name: 'animals',
        component: AnimalsView,
        meta: { requiresAuth: true, allowedRoles: [EUserType.ADMIN, EUserType.FARMER, EUserType.FARM_MANAGER, EUserType.VET] }
      },
      {
        path: 'animals/:id',
        name: 'animalView',
        component: AnimalView,
        props: true,
        meta: { requiresAuth: true, allowedRoles: [EUserType.ADMIN, EUserType.FARMER, EUserType.FARM_MANAGER, EUserType.VET] }
      },
      {
        path: 'reports/:id',
        name: 'reports',
        component: ReportsView,
        props: true,
        meta: { requiresAuth: true, allowedRoles: [EUserType.ADMIN, EUserType.FARMER, EUserType.FARM_MANAGER, EUserType.VET] }
      },
      {
        path: '/add-report/:id',
        name: 'reportAdd',
        component: AddReportView,
        props: true,
        meta: { requiresAuth: true, allowedRoles: [EUserType.ADMIN, EUserType.VET] }
      }
    ]
  },
  {
    path: '/forgot-password',
    name: 'forgotPassword',
    component: ForgotPasswordView
  },
  {
    path: '/reset-password',
    name: 'resetPassword',
    component: ResetPasswordView,
    props: route => ({ token: route.query.token })
  },
  {
    path: '/login',
    name: 'login',
    component: LoginView
  },
];

const router = new VueRouter({
  mode: 'history',
  routes
});

// router.beforeEach((to, from, next) => {
//   const isAuthenticated = store.getters[EGettersType.GET_IS_AUTHENTICATED];
//   const userRole = store.getters[EGettersType.GET_USER_ROLE];
//   const requiresAuth = to.matched.some(record => record.meta.requiresAuth);
//   const allowedRoles = to.matched.some(record => record.meta.allowedRoles);

//   if (requiresAuth && !isAuthenticated) {
//     if (to.path !== '/login') {
//       next('/login');
//     } else {
//       next();
//     }
//   } else if (requiresAuth && allowedRoles && !to.meta!.allowedRoles.includes(userRole)) {
//     next('/metrics'); // Redirect to a default authorized page
//   } else {
//     if (to.path === '/' && isAuthenticated) {
//       next('/metrics');
//     } else {
//       next();
//     }
//   }
// });

router.beforeEach((to, from, next) => {
  const isAuthenticated = store.getters[EGettersType.GET_IS_AUTHENTICATED];
  const userRole = getUserRole();
  const requiresAuth = to.matched.some(record => record.meta.requiresAuth);
  const allowedRoles = to.matched.some(record => record.meta.allowedRoles);

  // Redirect unauthenticated users to the login page
  if (requiresAuth && !isAuthenticated) {
    console.log("redirecting to login");
    return next('/login');
  }

  // Check for allowed roles
  if (requiresAuth && allowedRoles && to.meta!.allowedRoles && !to.meta!.allowedRoles.includes(userRole)) {
    return next('/metrics'); // Redirect to an accessible page
  }

  // Handle root redirection
  if (to.path === '/' && isAuthenticated) {
    return next('/metrics'); // Redirect to default page after login
  }

  next();
});

export default router;
