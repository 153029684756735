<template>
  <div>
    <h1>Add New User</h1>
    <form @submit.prevent="handleSubmit" class="user-form">
      <div class="form-group">
        <label for="firstName">First Name:</label>
        <input type="text" id="firstName" v-model="firstName" required />
      </div>
      <div class="form-group">
        <label for="lastName">Last Name:</label>
        <input type="text" id="lastName" v-model="lastName" required />
      </div>
      <div class="form-group">
        <label for="email">Email:</label>
        <input type="email" id="email" v-model="email" required />
        <span v-if="errors.email" class="error-message">{{ errors.email }}</span>
      </div>
      <div class="form-group">
        <label for="phone">Phone:</label>
        <input type="tel" id="phone" v-model="phone" required />
        <span v-if="errors.phone" class="error-message">{{ errors.phone }}</span>
      </div>
      <div class="form-group">
        <label for="userType">User Type:</label>
        <select id="userType" v-model="userType" required>
          <option value="ADMIN">ADMIN</option>
          <option value="FARMER">FARMER</option>
          <option value="FARM_MANAGER">FARM MANAGER</option>
          <option value="VET">VETERINARIAN</option>
        </select>
      </div>
      <button type="submit" class="submit-button" :disabled="loading">
        <span v-if="loading">Loading...</span>
        <span v-else>Add User</span>
      </button>
    </form>
  </div>
</template>

<script lang="ts">
import { DEFAULT_ERROR_MESSAGE_WITH_ENGINEERS, EModificationType } from '../globals';
import Vue from 'vue';
import { parsePhoneNumberFromString } from 'libphonenumber-js';

export default Vue.extend({
  data() {
    return {
      firstName: '',
      lastName: '',
      email: '',
      phone: '',
      userType: '',
      errors: {
        email: '',
        phone: ''
      },
      loading: false
    };
  },
  watch: {
    phone(newPhone) {
      if (!this.validatePhone(newPhone)) {
        this.errors.phone = 'Invalid phone number for Botswana.';
      } else {
        this.errors.phone = '';
      }
    },
    email(newEmail) {
      if (!this.validateEmail(newEmail)) {
        this.errors.email = 'Invalid email address.';
      } else {
        this.errors.email = '';
      }
    }
  },
  methods: {
    validateEmail(email: string) {
      const emailPattern = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
      return emailPattern.test(email);
    },
    validatePhone(phone: string) {
      const phoneNumber = parsePhoneNumberFromString(phone, 'BW');
      return phoneNumber && phoneNumber.isValid();
    },
    handleSubmit: async function () {
      this.errors.email = '';
      this.errors.phone = '';

      if (!this.validateEmail(this.email)) {
        this.errors.email = 'Invalid email address.';
        return;
      }

      if (!this.validatePhone(this.phone)) {
        this.errors.phone = 'Invalid phone number for Botswana.';
        return;
      }

      // Format phone number before submission
      const phoneNumber = parsePhoneNumberFromString(this.phone, 'BW');
      if (phoneNumber && phoneNumber.isValid()) {
        this.phone = phoneNumber.formatInternational();
      }

      this.loading = true; // Start loading

      try {
        await this.$store.dispatch(EModificationType.CREATE_USER, {
          firstName: this.firstName,
          lastName: this.lastName,
          email: this.email,
          phone: this.phone,
          userType: this.userType
        });
        this.$router.push({ name: 'users' }); // Navigate back to users list
      } catch (e) {
        if (e instanceof Error) {
          console.log('Show on interface: ', e);
        } else {
          console.log('Show on interface: ', DEFAULT_ERROR_MESSAGE_WITH_ENGINEERS);
        }
      } finally {
        this.loading = false; // Stop loading
      }
    }
  },
});
</script>

<style scoped>
.user-form {
  display: flex;
  flex-direction: column;
  max-width: 400px; /* Adjust as needed */
  margin: auto; /* Center the form horizontally */
}

.form-group {
  margin-bottom: 1rem; /* Adjust spacing between form groups */
}

label {
  display: block;
  margin-bottom: 5px;
}

input,
select {
  width: 100%;
  padding: 10px;
  margin-top: 5px;
  border: 1px solid #ccc;
  border-radius: 5px;
}

.error-message {
  color: red;
  font-size: 0.875rem;
  margin-top: 5px;
}

.submit-button {
  display: block;
  width: 100%;
  padding: 10px;
  background-color: #2dce89;
  color: #fff;
  border: none;
  border-radius: 5px;
  cursor: pointer;
  font-size: 16px;
  font-weight: bold;
}

.submit-button:disabled {
  background-color: #ccc;
  cursor: not-allowed;
}

.submit-button:hover {
  background-color: #1db674;
}
</style>