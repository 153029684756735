
<template>
  <div class="metrics-view-container">
    <h2>Hello {{ user.firstName }}</h2>
    <div class="metrics-grid">
      <weather-widget class="grid-item wide"></weather-widget>
      <location-widget class="grid-item"></location-widget>
      <div class="grid-item metric point" @click="navigateToAllAnimals">
        <h3>Total Animals</h3>
        <p v-show="isLoading">...</p>
        <p v-show="!isLoading">{{ totalAnimals }}</p>
      </div>
      <div class="grid-item metric">
        <h3>Male vs Female</h3>
        <p v-show="isLoading">...</p>
        <p v-show="!isLoading">Male: {{ totalMales }}, Female: {{ totalFemales }}</p>
      </div>
      <div class="grid-item metric point" @click="navigateToSickAnimals">
        <h3>Sick Animals</h3>
        <p v-show="isLoading">...</p>
        <p v-show="!isLoading">{{ ailingAnimals }} out of {{ totalAnimals }}</p>
      </div>
      <div class="grid-item metric point" @click="navigateToAnimalsInGestation">
        <h3>Females in Gestation</h3>
        <p v-show="isLoading">...</p>
        <p v-show="!isLoading">{{ gestatingFemales }}</p>
      </div>
    </div>
  </div>
</template>


<script lang="ts">
import Vue from 'vue';
import WeatherWidget from '@/components/WeatherWidget.vue';
import LocationWidget from '@/components/LocationWidget.vue';
import {
  DEFAULT_ERROR_MESSAGE_WITH_ENGINEERS,
  EAnimalSexType,
  EGestationStatus,
  EGettersType, EHealthStatus,
  EModificationType
} from "@/globals";
import {mapGetters} from "vuex";

export default Vue.extend({
  name: 'MetricsView',
  data() {
    return {
      isLoading: false,
      ailingAnimals: 0,
      gestatingFemales: 0,
      totalAnimals: 0,
      totalFemales: 0,
      totalMales: 0
    }
  },
  mounted: async function (): Promise<void> {
    try {
      if (this.fcmToken) {
        await this.$store.dispatch(EModificationType.SET_FIREBASE_DEVICE_TOKEN_ON_OWN_ACCOUNT, {
          firebaseDeviceToken: this.fcmToken
        });
      }
    } catch (error) {
      console.log(error);
    }
  },
  created: async function () {
    try {
      this.isLoading = true;
      const {
        ailingAnimals,
        gestatingFemales,
        totalAnimals,
        totalFemales,
        totalMales
      } = await this.$store.dispatch(EModificationType.FETCH_ANIMAL_STATS);

      this.ailingAnimals = ailingAnimals;
      this.totalMales = totalMales;
      this.totalFemales = totalFemales;
      this.totalAnimals = totalAnimals;
      this.gestatingFemales = gestatingFemales;
    } catch (e) {
      if (e instanceof Error) {
        console.log('Show on interface: ', e);
      } else {
        console.log('Show on interface: ', DEFAULT_ERROR_MESSAGE_WITH_ENGINEERS);
      }
    } finally {
      this.isLoading = false;
    }
  },
  computed: {
    ...mapGetters([
      EGettersType.GET_USER,
      EGettersType.GET_FCM_TOKEN
    ])
  },
  components: {
    'weather-widget': WeatherWidget,
    'location-widget': LocationWidget
  },
  methods: {
    navigateToAllAnimals() {
      this.$router.push({
        name: 'animals'
      });
    },
    navigateToAnimalsInGestation() {
      this.$router.push({
        name: 'animals',
        query: {
          sex: EAnimalSexType.FEMALE,
          inGestation: EGestationStatus.TRUE
        }
      });
    },
    navigateToSickAnimals() {
      this.$router.push({
        name: 'animals',
        query: {
          healthStatus: EHealthStatus.AILING
        }
      });
    }
  },
});
</script>

<style scoped>
.metrics-view-container {
  max-width: 1200px;
  margin: 20px auto;
  padding: 20px;
}

.metrics-grid {
  display: grid;
  grid-template-columns: repeat(4, 1fr); /* Define four columns for the second row */
  grid-template-rows: auto auto; /* Define two rows */
  gap: 20px;
}

.grid-item {
  border: 2px solid #666666;
  padding: 20px;
  background-color: #fff;
  border-radius: 8px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  text-align: center;
}

.grid-item.wide {
  grid-column: span 3; /* Each wide widget takes up two columns */
}

.metric h3 {
  
  color: #333;
  font-size: 16px;
  margin-bottom: 5px;
}

.point {
  cursor: pointer;
}
</style>
