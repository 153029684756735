import Vue from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
import 'leaflet/dist/leaflet.css';
import {EModificationType} from "./globals";
import {getAccessToken} from "./util";
import { messaging } from './firebase';
import { onMessage } from 'firebase/messaging';
import { getToken } from 'firebase/messaging';
const VAPID_KEY = "BLKL410Z7sTIhrTYhkWhVuBpaGt7eje4myyfZf-KEgA8pkRWXJx0_pLivSXvT4exqEQOak77WK0V_GVfB8aFS9s";

Vue.config.productionTip = false;

const token = getAccessToken();
if (token && token.length > 0 && token !== "undefined") {
  store.commit(EModificationType.SET_IS_AUTHENTICATED, true);
  await store.dispatch(EModificationType.FETCH_OWN_ACCOUNT);
}

if (messaging) {
  try {

    if ('serviceWorker' in navigator) {
      const swregistration = await navigator.serviceWorker.register('/firebase-messaging-sw.js');
      // Request notification permission from the browser
      const permission = await Notification.requestPermission();
      if (permission === 'granted') {
        // Get the FCM token after permission is granted
        const token = await getToken(messaging, { vapidKey: VAPID_KEY });
        store.commit(EModificationType.SET_FCM_TOKEN, token);
        onMessage(messaging, (payload) => {
          if (payload.notification && payload.notification.title) {
            if (payload.notification.body) {
              new Notification(payload.notification.title, { body: payload.notification.body });
            } else {
              new Notification(payload.notification.title);
            }
          }
        });
      } else {
        console.log('Notification permission denied.');
      }

    } else {
      console.warn('Service Worker is not supported in this browser.');
    }
  } catch (error) {
    console.error('Error getting notification permission:', error);
  }
} else {
  console.warn('Firebase Messaging is not supported in this environment.');
}

new Vue({
  router,
  store,
  render: h => h(App)
}).$mount('#app')
