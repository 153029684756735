<template>
  <div>
    <h1>Vendors</h1>
    <VendorList />
  </div>
</template>

<script>
import VendorList from '../components/VendorList.vue';

export default {
  name: 'VendorsView',
  components: {
    VendorList,
  },
};
</script>