<template>
  <div class="report-list-container">
    <h2>Report List For EID: {{ animal.eid }}</h2>
    <router-link v-show="shouldShow([userType.farmer, userType.admin])" :to="{ name: 'reportAdd', params: { animal: animal } }">
      <button class="add-report-btn">Create New Report</button>
    </router-link>
    <div class="filters">
      <div class="filter-item">
        <label for="start-date">Start Date:</label>
        <input type="date" id="start-date" v-model="startDate" @change="fetchReports">
      </div>
      <div class="filter-item">
        <label for="end-date">End Date:</label>
        <input type="date" id="end-date" v-model="endDate" @change="fetchReports">
      </div>
    </div>
    <div v-if="isLoading" class="loading">Loading...</div>
    <div v-else>
      <table v-if="reports.length > 0" class="report-table">
        <thead>
        <tr>
          <th>Date</th>
          <th>Type</th>
          <th>Notes</th>
          <th>Diagnosis</th>
          <th>Drug/Vaccine</th>
          <th>Weight (KG)</th>
          <th>Created By</th>
          <th>Disease Report Form</th>
        </tr>
        </thead>
        <tbody>
        <tr v-for="report in reports" :key="report.id">
          <td>{{ formattedDate(report.createdDate) }}</td>
          <td>{{ report.reportType }}</td>
          <td>{{ report.notes }}</td>
          <td>{{ report.diseaseId?.DiseaseDescription || 'N/A' }}</td>
          <td>{{ report.vaccineId?.CommonName || report.treatmentId?.TreatmentTypeDescription || 'N/A' }}</td>
          <td>{{ report.weight || 'N/A' }}</td>
          <td>
            {{ report.createdBy ? `${report.createdBy.firstName} ${report.createdBy.lastName}` : 'N/A' }}
          </td>
          <td>
            <template v-if="isDRFApplicable(report.reportType)">
              <input type="file" :ref="'fileInput' + report.id" style="display: none;" @change="handleFileUpload(report.id)">
              <div v-if="!report.diseaseReportForm">
                <label class="upload-drf-label" :for="'fileInput-' + report.id">
                  {{ uploadingReportId === report.id ? 'Uploading...' : 'Upload DRF' }}
                </label>
                <input type="file" :id="'fileInput-' + report.id" accept=".pdf" @change="validateAndUpload($event, report.id)" style="display: none;">
              </div>
              <div v-else>
                <button @click="downloadDRF(report.diseaseReportForm, report.id)" class="download-drf-btn">Download DRF</button>
                <button @click="removeDRF(report.id)" class="remove-drf-btn" :disabled="deletingReportId === report.id">
                  {{ deletingReportId === report.id ? 'Deleting...' : 'Remove DRF' }}
                </button>
              </div>
            </template>
            <template v-else>
              N/A
            </template>
          </td>
        </tr>
        </tbody>
      </table>
      <p v-else class="no-reports-message">There are no reports for this animal (EID: {{ animal.eid }}).</p>
      <div class="pagination">
        <button @click="previousPage" :disabled="currentPage === 1">Previous</button>
        <span>Page {{ currentPage }}</span>
        <button @click="nextPage" :disabled="currentPage * itemsPerPage >= totalDocumentCount">Next</button>
      </div>
    </div>
  </div>
</template>

<script lang="ts">
import {
  DEFAULT_ERROR_MESSAGE_WITH_ENGINEERS,
  DEFAULT_LIST_LIMIT,
  EGettersType,
  EModificationType,
  EReportSortType,
  ESortOrderType,
  EUserType
} from '../globals';
import Vue from 'vue';
import { mapGetters } from 'vuex';

   interface IUser {
     firstName: string;
     lastName: string;
   }

   interface IAnimal {
    eid: string;
   }

   interface IVaccine {
    CommonName: string;
   }

   interface ITreatment {
    TreatmentTypeDescription: string;
   }

   interface IDisease {
    DiseaseDescription: string;
   }

   interface IReport {
     id: string;
     notes: string;
     reportType: string;
     vaccineId?: IVaccine;
     treatmentId?: ITreatment;
     createdBy: IUser;
     diseaseId?: IDisease;
     weight?: number;
     diseaseReportForm?: string;
     createdDate: string;
   }

export default Vue.extend({
  props: {
    id: {
      type: String,
      required: true,
    },
  },
  data() {
    return {
      animal: {} as IAnimal,
      isLoading: false,
      reports: [] as IReport[],
      currentPage: 1,
      totalDocumentCount: 0,
      itemsPerPage: DEFAULT_LIST_LIMIT,
      startDate: '',
      endDate: '',
      userType: {
        farmer: EUserType.FARMER,
        admin: EUserType.ADMIN,
        vet: EUserType.VET,
        farmManager: EUserType.FARM_MANAGER
      },
      uploadingReportId: null as string | null,
      deletingReportId: null as string | null,
    };
  },
  computed: {
    ...mapGetters([
      EGettersType.GET_USER,
      EGettersType.GET_USER_ROLE
    ])
  },
  created: async function () {
    this.fetchReports();
    try {
      await this.fetchAnimal();
      this.fetchReports();
    } catch (e) {
      if (e instanceof Error) {
        console.log('Show on interface: ', e);
      } else {
        console.log('Show on interface: ', DEFAULT_ERROR_MESSAGE_WITH_ENGINEERS);
      }
    }
  },
  methods: {
    async fetchAnimal() {
      try {
        this.animal = await this.$store.dispatch(EModificationType.FETCH_ANIMAL_BY_ID, {animalId: this.id});
      } catch (e) {
        if (e instanceof Error) {
          console.log('Show on interface: ', e);
        } else {
          console.log('Show on interface: ', DEFAULT_ERROR_MESSAGE_WITH_ENGINEERS);
        }
      }
    },
    fetchReports() {
      this.isLoading = true;
      this.$store.dispatch(EModificationType.FETCH_REPORTS, {
        animalId: this.id,
        sortOrder: ESortOrderType.DESC,
        sortBy: EReportSortType.DATE,
        limit: this.itemsPerPage,
        skip: (this.currentPage - 1) * this.itemsPerPage,
        startDate: this.startDate,
        endDate: this.endDate
      }).then((response) => {
        this.reports = response.reports;
        this.totalDocumentCount = response.count;
        this.isLoading = false;
        if (this.reports.length === 0) {
          this.currentPage = 1;
        }
      }).catch(e => {
        this.isLoading = false;
        console.log('Show on interface: ', e.message || DEFAULT_ERROR_MESSAGE_WITH_ENGINEERS);
      });
    },
    nextPage() {
      if (this.currentPage * this.itemsPerPage < this.totalDocumentCount) {
        this.currentPage++;
        this.fetchReports();
      }
    },
    previousPage() {
      if (this.currentPage > 1) {
        this.currentPage--;
        this.fetchReports();
      }
    },
    shouldShow(listOfUserType: Array<any>) {
      return listOfUserType.indexOf(this.userRole) > -1;
    },
    formattedDate(date: string | Date) {
      const reportDate = new Date(date);
      return reportDate.toLocaleDateString(this.user.locale, {
        year: 'numeric', month: '2-digit', day: '2-digit',
        hour: '2-digit', minute: '2-digit', second: '2-digit',
        hour12: false
      });
    },
    triggerFilePicker(reportId: string) {
      this.$nextTick(() => {
        const fileInput = this.$refs['fileInput' + reportId] as HTMLInputElement | undefined;
        if (fileInput && typeof fileInput.click === 'function') {
          fileInput.click();
        } else {
          console.error('File input reference is undefined or not a valid input element');
        }
      });
    },
    handleFileUpload(reportId: string) {
      const fileInput = this.$refs['fileInput' + reportId] as HTMLInputElement | undefined;
      if (fileInput && fileInput.files) {
        const file = fileInput.files[0];
        if (file) {
          console.log(`File selected for report ID: ${reportId}`, file);
          // Implement the file upload logic here
        }
      } else {
        console.error('File input reference is undefined or no file selected');
      }
    },
    validateAndUpload(event: Event, reportId: string) {
      const fileInput = event.target as HTMLInputElement;
      if (fileInput && fileInput.files) {
        const file = fileInput.files[0];
        if (file) {
          // Log file size in MB
          const fileSizeInMB = file.size / (1024 * 1024);
          console.log(`File size: ${fileSizeInMB.toFixed(2)} MB`);

          if (file.type !== 'application/pdf') {
            alert('Only PDF files are allowed.');
            fileInput.value = ''; // Clear the input
            return;
          }
          // Add file size check (5MB limit)
          const maxSizeInBytes = 5 * 1024 * 1024; // 5MB
          if (file.size > maxSizeInBytes) {
            alert('File size exceeds 5MB limit. Please choose a smaller file.');
            fileInput.value = ''; // Clear the input
            return;
          }
          console.log(`File selected for report ID: ${reportId}`, file);
          this.uploadFile(file, reportId);
        }
      }
    },
    async uploadFile(file: File, reportId: string) {
      this.uploadingReportId = reportId;
      try {
        const response = await this.$store.dispatch(EModificationType.UPLOAD_DRF, { reportId, file });

        if (response) {
          const reportIndex = this.reports.findIndex(report => report.id === reportId);
          if (reportIndex !== -1) {
            this.$set(this.reports[reportIndex], 'diseaseReportForm', response.diseaseReportForm);
          }
          console.log('DRF uploaded successfully');
        } else {
          throw new Error('Failed to upload DRF');
        }
      } catch (error) {
        console.error('Error uploading DRF:', error);
        alert('Failed to upload DRF. Please try again.');
      } finally {
        this.uploadingReportId = null;
      }
    },
    downloadDRF(base64PDF: string, reportId: string) {
      const byteCharacters = atob(base64PDF);
      const byteNumbers = new Array(byteCharacters.length);
      for (let i = 0; i < byteCharacters.length; i++) {
        byteNumbers[i] = byteCharacters.charCodeAt(i);
      }
      const byteArray = new Uint8Array(byteNumbers);
      const blob = new Blob([byteArray], { type: 'application/pdf' });
      
      const link = document.createElement('a');
      link.href = window.URL.createObjectURL(blob);
      link.download = `DRF_Report_${reportId}.pdf`;
      link.click();
      window.URL.revokeObjectURL(link.href);
    },
    isDRFApplicable(reportType: string): boolean {
      return reportType === 'TREATMENT_REPORT' || reportType === 'VACCINE_REPORT';
    },
    async removeDRF(reportId: string) {
      if (confirm('Are you sure you want to remove this DRF?')) {
        this.deletingReportId = reportId;
        try {
          await this.$store.dispatch(EModificationType.REMOVE_DRF, { reportId });
          const reportIndex = this.reports.findIndex(report => report.id === reportId);
          if (reportIndex !== -1) {
            this.$set(this.reports[reportIndex], 'diseaseReportForm', undefined);
          }
          console.log('DRF removed successfully');
        } catch (error) {
          console.error('Error removing DRF:', error);
          alert('Failed to remove DRF. Please try again.');
        } finally {
          this.deletingReportId = null;
        }
      }
    },
  }
});
</script>

<style scoped>
.report-list-container {
  max-width: 1200px;
  margin: 0 auto;
  padding: 20px;
}

h2 {
  
  font-size: 24px;
  font-weight: bold;
  margin-bottom: 20px;
}

.add-report-btn {
  
  padding: 10px 20px;
  font-size: 16px;
  background-color: #2dce89;
  color: #fff;
  border: none;
  border-radius: 4px;
  cursor: pointer;
  margin-bottom: 20px;
}

.add-report-btn:hover {
  background-color: #1db674;
}

.filters {
  display: flex;
  justify-content: flex-start;
  margin-bottom: 20px;
}

.filter-item {
  display: flex;
  align-items: center;
  margin: 0 10px 0 0;
}

.filter-item label {
  margin-right: 10px;
}

input {
  padding: 8px;
  border-radius: 4px;
  border: 1px solid #ccc;
}

.report-table {
  width: 100%;
  border-collapse: collapse;
}

.report-table th,
.report-table td {
  padding: 15px;
  text-align: left;
  border-bottom: 2px solid #666666;
}

.report-table th {
  background-color: #f4f4f4;
}

.loading {
  
  font-size: 18px;
  text-align: center;
  margin: 20px 0;
}

.no-reports-message {
  
  margin: 100px 0;
  font-size: 18px;
  text-align: center;
  color: #888;
}

button {
  background-color: #007bff;
  color: white;
  border: none;
  padding: 8px 12px;
  border-radius: 4px;
  cursor: pointer;
}

button:hover {
  background-color: #0056b3;
}

.pagination {
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: 20px;
}

.pagination button {
  margin: 0 5px;
}

.upload-drf-label {
  background-color: #007bff;
  color: white;
  padding: 8px 12px;
  border-radius: 4px;
  cursor: pointer;
  display: inline-block;
  text-align: center;
  margin-top: 5px;
  font-size: 14px;
}

.upload-drf-label:hover {
  background-color: #0056b3;
}

.download-drf-btn {
  background-color: #28a745;
  color: white;
  padding: 8px 12px;
  border-radius: 4px;
  cursor: pointer;
  border: none;
  display: inline-block;
  text-align: center;
  margin-top: 5px;
  font-size: 14px;
}

.download-drf-btn:hover {
  background-color: #218838;
}

.remove-drf-btn {
  background-color: #dc3545;
  color: white;
  padding: 8px 12px;
  border-radius: 4px;
  cursor: pointer;
  border: none;
  display: inline-block;
  text-align: center;
  margin-top: 5px;
  margin-left: 5px;
  font-size: 14px;
}

.remove-drf-btn:hover {
  background-color: #c82333;
}

.remove-drf-btn:disabled {
  background-color: #c82333;
  cursor: not-allowed;
}

.remove-drf-btn:disabled:hover {
  background-color: #c82333;
}
</style>
