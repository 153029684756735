<template>
  <!-- Open Weather Map -->
  <vue-weather class="weather-widget"
      :api-key="apiKey"
      units="uk"
      :latitude="latitude"
      :longitude="longitude"
  ></vue-weather>
</template>

<script>
import VueWeather from "vue-weather-widget";

export default {
  data () {
    return {
      apiKey: process.env.VUE_APP_API_KEY,
      latitude: "-26.5858230",
      longitude: "-26.5858230"
    }
  },
  components: {
    'vue-weather': VueWeather,
  },
};
</script>


<style scoped>
  .weather-widget {
    width: 95%;
  }
</style>