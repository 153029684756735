<template>
  <div class="animal-view-container">
    <h2>Animal Details</h2>
    <div class="animal-details">
      <p><strong>Tag EID:</strong> {{ animal.eid }}</p>
      <p><strong>Breed:</strong> {{ animal.ModisarAnimalDataDump.AnimalBreedID.AnimalBreedDescription }}</p>
      <p><strong>Gender:</strong> {{ animal.sex }}</p>
      <p><strong>Current Weight (kg):</strong> {{ animal.weights && animal.weights.length > 0 ? animal.weights[animal.weights.length - 1].weight : 'N/A' }}</p>
      <p><strong>Date of Birth:</strong> {{ new Date(animal.ModisarAnimalDataDump.DateOfBirth).toLocaleDateString() }}</p>
      <p><strong>Health Status:</strong> {{ healthStatus }}</p>
      <p><strong>Has Health Sensor:</strong> {{ animal.sensorId ? 'Yes' : 'No' }}</p>
      <!-- <p><strong>Number of Offspring:</strong> {{ numberOfOffspring }}</p> -->
      <p v-show="isFemale"><strong>Is In Gestation:</strong> {{ isAnimalInGestation }}</p>
    </div>

    <!-- Date picker and dropdown for selecting frequency -->
    <div class="controls">
      <div class="date-picker">
        <label for="date-select">Select Date: </label>
        <input type="date" id="date-select" v-model="selectedDate" />
      </div>
      <div class="frequency-dropdown">
        <label for="frequency-select">Frequency: </label>
        <select id="frequency-select" v-model="frequency">
          <option value="HOURLY">HOURLY</option>
          <option value="DAILY">DAILY</option>
        </select>
      </div>
    </div>

    <div class="charts-grid">
      <div class="chart-item">
        <h3>{{ heartRateLabel }}</h3>
        <LineChart :labels="heartRateLabels" :data="heartRateData" :frequency="frequency" :label="heartRateLabel" :bounds="vitalBounds[animal.animalSpecies].HEART_RATE"></LineChart>
      </div>
      <div class="chart-item">
        <h3>{{ phLabel }}</h3>
        <LineChart :labels="pHLabels" :data="pHData" :frequency="frequency" :label="phLabel" :bounds="vitalBounds[animal.animalSpecies].PH"></LineChart>
      </div>
      <div class="chart-item">
        <h3>{{ temperatureLabel }}</h3>
        <LineChart :labels="temperatureLabels" :data="temperatureData" :frequency="frequency" :label="temperatureLabel" :bounds="vitalBounds[animal.animalSpecies].TEMPERATURE"></LineChart>
      </div>
      <div class="chart-item">
        <h3>{{ bloodOxygenLevelLabel }}</h3>
        <LineChart :labels="bloodOxygenLevelLabels" :data="bloodOxygenLevelData" :frequency="frequency" :label="bloodOxygenLevelLabel" :bounds="vitalBounds[animal.animalSpecies].BLOOD_OXYGEN_LEVEL"></LineChart>
      </div>
    </div>

    <!-- Update Section -->
    <div v-show="!isDeceased && shouldShow([userType.farmer, userType.admin])" class="update-section">
      <h3>Animal Status Update</h3>
      <form>
        <div class="form-group">
          <label for="new-weight">New Animal Weight (kg):</label>
          <input type="number" id="new-weight" v-model="update.newWeight" min="0" />
          <button @click="updateWeight" class="update-weight-btn">Update Weight</button>
        </div>
        <div v-show="isFemale" class="form-group">
          <label for="gestation">In Gestation:</label>
          <input type="checkbox" id="gestation" v-model="update.gestation" />
        </div>
        <!-- New gestation details -->
        <div v-if="update.gestation" class="gestation-details">
          <label for="gestation-start">Gestation Start Date:</label>
          <input type="date" id="gestation-start" v-model="update.gestationStartDate" />
          <br />
          <label for="due-date">Expected Due Date:</label>
          <input type="date" id="due-date" :value="calculatedDueDate" disabled />

            <p v-show="update.gestationStartDate">Current Gestation Progress: {{ gestationProgress }}%</p>
            <p v-show="update.gestationStartDate">Current Trimester: {{ calculateGestationTrimester }}</p>
        </div>
      </form>
    </div>

    <div class="button-container">
      <button v-show="shouldShow([userType.admin, userType.farmer, userType.vet])" class="view-reports-btn" @click="viewReports(animal.id)">View Reports</button>
      <button v-if="!animal.sensorId" class="link-sensor-btn" @click="linkSensor(animal.id)">Link Health Sensor</button>
    </div>
  </div>
</template>

<script lang="ts">
import { DEFAULT_ERROR_MESSAGE_WITH_ENGINEERS, EAnimalSexType, EFetchVitalFrequency, EGestationDurationDays, EGettersType, EHealthStatus, EModificationType, EUserType, EVitalType, vitalBounds } from '../globals';
import Vue from 'vue';
import LineChart from '../components/LineChart.vue';
import { mapGetters } from 'vuex';

interface IWeight {
  weight: number;
  recordedDate: string;
}

const pHDataNoneNeverArray: Array<any> = [];
const pHLabelsNoneNeverArray: Array<any> = [];
const temperatureDataNoneNeverArray: Array<any> = [];
const temperatureLabelsNoneNeverArray: Array<any> = [];
const heartRateDataNoneNeverArray: Array<any> = [];
const heartRateLabelsNoneNeverArray: Array<any> = [];
const bloodOxygenLevelDataNoneNeverArray: Array<any> = [];
const bloodOxygenLevelLabelsNoneNeverArray: Array<any> = [];

export default Vue.extend({
  created: async function () {
    try {
      this.animal.id = this.id;
      await this.fetchAnimal();
      this.update.gestation = this.animal.isInGestation;
      this.update.underTreatment = this.animal.isUnderTreatment;

      // Format gestationStartDate to 'YYYY-MM-DD'
      if (this.animal.gestationStartDate) {
        const date = new Date(this.animal.gestationStartDate);
        const year = date.getFullYear();
        const month = String(date.getMonth() + 1).padStart(2, '0');
        const day = String(date.getDate()).padStart(2, '0');
        this.update.gestationStartDate = `${year}-${month}-${day}`;
      } else {
        this.update.gestationStartDate = '';
      }

      await this.getHourlyData(this.selectedDate);
    } catch (e) {
      if (e instanceof Error) {
        console.log('Show on interface: ', e);
      } else {
        console.log('Show on interface: ', DEFAULT_ERROR_MESSAGE_WITH_ENGINEERS);
      }
    }
  },
  components: {
    LineChart,
  },
  props: {
    id: {
      type: String,
      required: true,
    },
  },
  watch: {
    selectedDate: async function (n) {
      if (n) {
        if (this.frequency === EFetchVitalFrequency.DAILY) {
          await this.getDailyData(n);
        }
        if (this.frequency === EFetchVitalFrequency.HOURLY) {
          await this.getHourlyData(n);
        }
      }
    },
    frequency: async function (n) {
      if (n === EFetchVitalFrequency.DAILY) {
        await this.getDailyData(this.selectedDate);
      }
      if (n === EFetchVitalFrequency.HOURLY) {
        await this.getHourlyData(this.selectedDate);
      }
    },
    'update.gestationStartDate': async function (n) {
      await this.updateGestationDetails();
    },
    'update.gestation': async function (n) {
      if (!n) {
        await this.updateGestationDetails();
      }
    },
    'update.underTreatment': async function (n) {
      try {
        await this.$store.dispatch(EModificationType.UPDATE_TREATMENT_STATUS, { animalId: this.animal.id, isUnderTreatment: n });
      } catch (e) {
        if (e instanceof Error) {
          console.log('Show on interface: ', e);
        } else {
          console.log('Show on interface: ', DEFAULT_ERROR_MESSAGE_WITH_ENGINEERS);
        }
      }
    }
  },
  data() {
    return {
      vitalBounds,
      animal: {
        gestationStartDate: '',
        eid: '',
        id: '',
        sex: '',
        sensorId: '',
        animalSpecies: '',
        isInGestation: false,
        isUnderTreatment: false,
        healthStatus: EHealthStatus.HEALTHY,
        weights: [] as Array<IWeight>
      },
      numberOfOffspring: 0,
      heartRateLabel: 'Heart Rate',
      phLabel: 'Gastric pH',
      temperatureLabel: 'Temperature',
      bloodOxygenLevelLabel: 'Blood Oxygen Level',
      pHLabels: pHLabelsNoneNeverArray,
      pHData: pHDataNoneNeverArray,
      heartRateLabels: heartRateLabelsNoneNeverArray,
      heartRateData: heartRateDataNoneNeverArray,
      bloodOxygenLevelLabels: bloodOxygenLevelLabelsNoneNeverArray,
      bloodOxygenLevelData: bloodOxygenLevelDataNoneNeverArray,
      temperatureLabels: temperatureLabelsNoneNeverArray,
      temperatureData: temperatureDataNoneNeverArray,
      frequency: EFetchVitalFrequency.HOURLY,
      selectedDate: new Date().toISOString().slice(0, 10),
      update: {
        gestation: false,
        underTreatment: false,
        healthStatus: '',
        newWeight: null,
        gestationStartDate: ''
      },
      userType: {
        farmer: EUserType.FARMER,
        admin: EUserType.ADMIN,
        vet: EUserType.VET,
        farmManager: EUserType.FARM_MANAGER
      }
    };
  },
  computed: {
    ...mapGetters([
      EGettersType.GET_USER_ROLE
    ]),
    isFemale: function (): boolean {
      return this.animal.sex === EAnimalSexType.FEMALE;
    },
    healthStatus: function (): string {
      if (this.animal.healthStatus === EHealthStatus.AILING) {
        return "SICK";
      } else if (this.animal.healthStatus === EHealthStatus.DECEASED) {
        return "DEAD";
      } else {
        return "HEALTHY";
      }
    },
    isDeceased: function (): boolean {
      return this.animal.healthStatus === EHealthStatus.DECEASED;
    },
    isAnimalInGestation: function (): string {
      return this.animal.isInGestation ? "Yes" : "No";
    },
    isAnimalUnderTreatment: function (): string {
      return this.animal.isUnderTreatment ? "Yes" : "No";
    },
    gestationDurationDays: function (): number {
      return EGestationDurationDays[this.animal.animalSpecies as keyof typeof EGestationDurationDays] || 0;
    },
    calculatedDueDate(): string {
      if (this.update.gestationStartDate) {
        const startDate = new Date(this.update.gestationStartDate);
        const dueDate = new Date(startDate);
        dueDate.setDate(startDate.getDate() + this.gestationDurationDays);
        return dueDate.toISOString().split('T')[0];
      }
      return 'N/A';
    },
    calculateGestationTrimester(): string {
      return this.gestationProgress < 33 ? 'First' : this.gestationProgress < 66 ? 'Second' : 'Third';
    },  
    gestationProgress(): number {
      if (this.update.gestationStartDate) {
        const startDate = new Date(this.update.gestationStartDate);
        const today = new Date();
        const elapsedDays = Math.floor((today.getTime() - startDate.getTime()) / (1000 * 60 * 60 * 24));
        return Math.min(Math.floor((elapsedDays / this.gestationDurationDays) * 100), 100);
      }
      return 0;
    },
  },
  methods: {
    async updateGestationDetails() {
      try {
        await this.$store.dispatch(EModificationType.UPDATE_GESTATION_STATUS, {
          animalId: this.animal.id,
          isInGestation: this.update.gestation,
          gestationStartDate: this.update.gestationStartDate
        });
        if (!this.update.gestation) {
          this.update.gestationStartDate = '';
        }
      } catch (e) {
        if (e instanceof Error) {
          console.log('Show on interface: ', e);
        } else {
          console.log('Show on interface: ', DEFAULT_ERROR_MESSAGE_WITH_ENGINEERS);
        }
      }
    },
    shouldShow: function (listOfUserType: Array<any>) {
      return listOfUserType.indexOf(this.userRole) > -1;
    },
    linkSensor: function (animalId: any) {
      this.$router.push({ name: 'sensorLink', params: { id: animalId } });
    },
    viewReports: function (animalId: any) {
      this.$router.push({ name: 'reports', params: { id: animalId } });
    },
    sortVitalsByRecordedDate: function (vitals: Array<any>) {
      // Ensure we are working with a copy of the array
      const sortedVitals = [...vitals];

      // Sort the array by recordedDate in ascending order
      sortedVitals.sort((a: any, b: any) => {
        const dateA: Date = new Date(a.recordedDate);
        const dateB: Date = new Date(b.recordedDate);

        return (dateA as any) - (dateB as any);
      });

      return sortedVitals;
    },
    getHourlyData: async function (selectedDate: string) {
      const result = await Promise.all([
        this.$store.dispatch(EModificationType.FETCH_HOURLY_VITALS, { limit: 100, vitalType: EVitalType.PH, animalId: this.animal.id, date: selectedDate }),
        this.$store.dispatch(EModificationType.FETCH_HOURLY_VITALS, { limit: 100, vitalType: EVitalType.TEMPERATURE, animalId: this.animal.id, date: selectedDate }),
        this.$store.dispatch(EModificationType.FETCH_HOURLY_VITALS, { limit: 100, vitalType: EVitalType.HEART_RATE, animalId: this.animal.id, date: selectedDate }),
        this.$store.dispatch(EModificationType.FETCH_HOURLY_VITALS, { limit: 100, vitalType: EVitalType.BLOOD_OXYGEN_LEVEL, animalId: this.animal.id, date: selectedDate }),
      ]);

      if (result[0].length > 0) {
        const result2 = await Promise.all([(result[0] as Array<any>).map(entry => new Date(entry.recordedDate)), (result[0] as Array<any>).map(entry => entry.value)]);
        this.pHLabels = result2[0];
        this.pHData = result2[1];
      }

      if (result[1].length > 0) {
        const result2 = await Promise.all([(result[1] as Array<any>).map(entry => new Date(entry.recordedDate)), (result[1] as Array<any>).map(entry => entry.value)]);
        this.temperatureLabels = result2[0];
        this.temperatureData = result2[1];
      }

      if (result[2].length > 0) {
        const result2 = await Promise.all([(result[2] as Array<any>).map(entry => new Date(entry.recordedDate)), (result[2] as Array<any>).map(entry => entry.value)]);
        this.heartRateLabels = result2[0];
        this.heartRateData = result2[1];
      }

      if (result[3].length > 0) {
        const result2 = await Promise.all([(result[3] as Array<any>).map(entry => new Date(entry.recordedDate)), (result[3] as Array<any>).map(entry => entry.value)]);
        this.bloodOxygenLevelLabels = result2[0];
        this.bloodOxygenLevelData = result2[1];
      }
    },
    getDailyData: async function (selectedDate: string) {
      const splitDate = selectedDate.split('-');
      const year = splitDate[0];
      const month = parseInt(splitDate[1]);

      const result = await Promise.all([
        this.$store.dispatch(EModificationType.FETCH_DAILY_VITALS, {vitalType: EVitalType.PH, animalId: this.animal.id, month, year }),
        this.$store.dispatch(EModificationType.FETCH_DAILY_VITALS, {vitalType: EVitalType.TEMPERATURE, animalId: this.animal.id, month, year }),
        this.$store.dispatch(EModificationType.FETCH_DAILY_VITALS, {vitalType: EVitalType.HEART_RATE, animalId: this.animal.id, month, year }),
        this.$store.dispatch(EModificationType.FETCH_DAILY_VITALS, {vitalType: EVitalType.BLOOD_OXYGEN_LEVEL, animalId: this.animal.id, month, year }),
      ]);

      if (result[0].length > 0) {
        const sortedArray = this.sortVitalsByRecordedDate(result[0]);
        const result2 = await Promise.all([sortedArray.map(entry => new Date(entry.recordedDate)), (result[0] as Array<any>).map(entry => entry.averageValue)]);
        this.pHLabels = result2[0];
        this.pHData = result2[1];
      }

      if (result[1].length > 0) {
        const sortedArray = this.sortVitalsByRecordedDate(result[1]);
        const result2 = await Promise.all([sortedArray.map(entry => new Date(entry.recordedDate)), (result[1] as Array<any>).map(entry => entry.averageValue)]);
        this.temperatureLabels = result2[0];
        this.temperatureData = result2[1];
      }

      if (result[2].length > 0) {
        const sortedArray = this.sortVitalsByRecordedDate(result[2]);
        const result2 = await Promise.all([sortedArray.map(entry => new Date(entry.recordedDate)), (result[2] as Array<any>).map(entry => entry.averageValue)]);
        this.heartRateLabels = result2[0];
        this.heartRateData = result2[1];
      }

      if (result[3].length > 0) {
        const sortedArray = this.sortVitalsByRecordedDate(result[3]);
        const result2 = await Promise.all([sortedArray.map(entry => new Date(entry.recordedDate)), (result[3] as Array<any>).map(entry => entry.averageValue)]);
        this.bloodOxygenLevelLabels = result2[0];
        this.bloodOxygenLevelData = result2[1];
      }
    },
    updateWeight: async function () {
      try {
        await this.$store.dispatch(EModificationType.UPDATE_ANIMAL_WEIGHT, { animalId: this.animal.id, weight: this.update.newWeight });
      } catch (e) {
        if (e instanceof Error) {
          console.log('Show on interface: ', e);
        } else {
          console.log('Show on interface: ', DEFAULT_ERROR_MESSAGE_WITH_ENGINEERS);
        }
      }
    },
    async fetchAnimal() {
      try {
         const result = await Promise.all([this.$store.dispatch(EModificationType.FETCH_ANIMAL_BY_ID, {animalId: this.id}), this.$store.dispatch(EModificationType.FETCH_NUMBER_OF_OFFSPRING, {animalId: this.id})]);
        this.animal = result[0];
        this.numberOfOffspring = result[1];
      } catch (e) {
        if (e instanceof Error) {
          console.log('Show on interface: ', e);
        } else {
          console.log('Show on interface: ', DEFAULT_ERROR_MESSAGE_WITH_ENGINEERS);
        }
      }
    }
  }
});
</script>

<style scoped>
.animal-view-container {
  max-width: 800px;
  margin: 0 auto;
  padding: 20px;
  overflow: hidden; /* Prevent overflow */
}

.animal-details {
  padding: 10px;
  border: 2px solid #666666;
  border-radius: 4px;
  background-color: #f9f9f9;
  margin-bottom: 20px;
}

.charts-grid {
  display: grid;
  grid-template-columns: 1fr; /* Display charts in one column */
  gap: 20px;
  margin-bottom: 20px;
}

.chart-item {
  border: 2px solid #666666;
  border-radius: 4px;
  padding: 10px;
  background-color: #fff;
}

.controls {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 20px;
}

.date-picker {
  float: left;
  margin-right: 20px;
}

.frequency-dropdown {
  float: right;
}

.date-picker label, .frequency-dropdown label {
  margin-right: 10px;
}

.update-section {
  margin-bottom: 20px;
}

.form-group {
  margin-bottom: 10px;
}

.link-sensor-btn {
  
  padding: 10px 20px;
  font-size: 16px;
  color: #fff;
  background-color: #007bff;
  border: none;
  border-radius: 4px;
  cursor: pointer;
}

.link-sensor-btn:hover {
  background-color: #0056b3;
}

.button-container {
  display: flex;
  gap: 50px; /* Adjust the gap between buttons */
  justify-content: center; /* Center the buttons horizontally */
  margin-top: 20px;
}

.view-reports-btn {
  
  padding: 10px 20px;
  font-size: 16px;
  color: #fff;
  border: none;
  border-radius: 4px;
  cursor: pointer;
  display: block;
  text-align: center;
}

.back-btn {
  background-color: #007bff;
}

.view-reports-btn {
  background-color: #2dce89;
}

.back-btn:hover {
  background-color: #0056b3;
}

.view-reports-btn:hover {
  background-color: #1db674;
}

.gestation-details {
  margin-top: 10px;
  padding: 10px;
  border: 1px solid #ccc;
  border-radius: 4px;
}
</style>
