<template>
  <div class="user-list-container">
    <h2>User List</h2>
    <router-link :to="{ name: 'userAdd' }">
      <button class="add-user-button">Add New User</button>
    </router-link>
    <div class="filters">
      <div class="filter-item">
        <label for="user-type">User Type:</label>
        <select id="user-type" v-model="selectedUserType" @change="fetchUsers">
          <option value="ALL">All</option>
          <option value="ADMIN">Admin</option>
          <option value="FARMER">Farmer</option>
          <option value="VET">Vet</option>
          <option value="FARM_MANAGER">Farm Manager</option>
        </select>
      </div>
    </div>
    <div v-if="isLoading" class="loading">Loading...</div>
    <div v-else>
      <table v-if="users.length" class="user-table">
        <thead>
        <tr>
          <th>Name</th>
          <th>Email</th>
          <th>Type</th>
          <th>Actions</th>
        </tr>
        </thead>
        <tbody>
        <tr v-for="user in users" :key="user.id">
          <td>{{ user.firstName }} {{ user.lastName }}</td>
          <td>{{ user.email }}</td>
          <td>{{ user.userType }}</td>
          <td>
            <button @click="viewUser(user.id)">View</button>
          </td>
        </tr>
        </tbody>
      </table>
      <div v-else class="no-users">No users available.</div>
      <div class="pagination">
        <button @click="previousPage" :disabled="currentPage === 1">Previous</button>
        <span>Page {{ currentPage }}</span>
        <button @click="nextPage" :disabled="currentPage * itemsPerPage >= totalDocumentCount">Next</button>
      </div>
    </div>
  </div>
</template>

<script lang="ts">
import { DEFAULT_ERROR_MESSAGE_WITH_ENGINEERS, DEFAULT_LIST_LIMIT, EGettersType, EModificationType, ESortOrderType, EUserSortType, EUserType } from '../globals';
import Vue from 'vue';
import { mapGetters } from 'vuex';

export default Vue.extend({
  data() {
    return {
      isLoading: false,
      selectedUserType: 'ALL',
      currentPage: 1,
      totalDocumentCount: 0,
      itemsPerPage: DEFAULT_LIST_LIMIT,
    };
  },
  created: function () {
    this.fetchUsers();
  },
  computed: {
    ...mapGetters([
      EGettersType.GET_USERS
    ])
  },
  methods: {
    fetchUsers() {
      this.isLoading = true;

      this.$store.dispatch(EModificationType.FETCH_USERS, {
        sortOrder: ESortOrderType.ASC,
        sortBy: EUserSortType.DATE,
        limit: this.itemsPerPage,
        skip: (this.currentPage - 1) * this.itemsPerPage,
        userType: this.selectedUserType !== 'ALL' ? this.selectedUserType : undefined
      }).then((count) => {
        this.totalDocumentCount = count;
        this.isLoading = false;
        if (this.users.length === 0) {
          this.currentPage = 1;
        }
      }).catch(e => {
        this.isLoading = false;
        console.log('Show on interface: ', e.message || DEFAULT_ERROR_MESSAGE_WITH_ENGINEERS);
      });
    },
    viewUser(userId: string) {
      this.$router.push({ name: 'userView', params: { id: userId } });
    },
    nextPage() {
      if (this.currentPage * this.itemsPerPage < this.totalDocumentCount) {
        this.currentPage++;
        this.fetchUsers();
      }
    },
    previousPage() {
      if (this.currentPage > 1) {
        this.currentPage--;
        this.fetchUsers();
      }
    }
  }
});
</script>

<style scoped>
.user-list-container {
  max-width: 1200px;
  margin: 0 auto;
  padding: 20px;
}

h2 {
  
  font-size: 24px;
  font-weight: bold;
  margin-bottom: 20px;
}

.add-user-button {
  
  padding: 10px 20px;
  font-size: 16px;
  background-color: #2dce89;
  color: #fff;
  border: none;
  border-radius: 4px;
  cursor: pointer;
  margin-bottom: 20px;
}

.add-user-button:hover {
  background-color: #1db674;
}

.filters {
  display: flex;
  justify-content: flex-start;
  margin-bottom: 20px;
}

.filter-item {
  display: flex;
  align-items: center;
  margin-right: 20px;
}

.filter-item label {
  margin-right: 10px;
}

select {
  padding: 8px;
  border-radius: 4px;
  border: 1px solid #ccc;
}

.user-table {
  width: 100%;
  border-collapse: collapse;
}

.user-table th,
.user-table td {
  padding: 15px;
  text-align: left;
  border-bottom: 2px solid #666666;
}

.user-table th {
  background-color: #f4f4f4;
}

.loading {
  
  font-size: 18px;
  text-align: center;
  margin: 20px 0;
}

.no-users {
  
  margin: 100px 0;
  font-size: 18px;
  text-align: center;
  color: #888;
}

button {
  background-color: #007bff;
  color: white;
  border: none;
  padding: 8px 12px;
  border-radius: 4px;
  cursor: pointer;
}

button:hover {
  background-color: #0056b3;
}

.pagination {
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: 20px;
}

.pagination button {
  margin: 0 5px;
}
</style>
