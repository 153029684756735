<template>
  <div>
    <h1>Animals</h1>
    <AnimalList />
  </div>
</template>

<script>
import AnimalList from '../components/AnimalList.vue';

export default {
  name: 'AnimalsView',
  components: {
    AnimalList,
  },
};
</script>