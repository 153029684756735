<template>
  <div>
    <h1>Users</h1>
    <UserList />
  </div>
</template>

<script>
import UserList from '../components/UserList.vue';

export default {
  name: 'UsersView',
  components: {
    UserList,
  },
};
</script>