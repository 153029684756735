<template>
  <div class="sensor-view-container" v-if="sensor">
    <h2>Sensor Details</h2>
    <div class="sensor-details">
      <p><strong>Sensor ID:</strong> {{ sensor.sensorId }}</p>
      <p><strong>Vendor Name:</strong> {{ vendor.name }}</p>
      <p v-show="sensor.animalId"><strong>Assigned Animal EID:</strong> {{ animal.eid }}</p>
    </div>
  </div>
</template>

<script lang="ts">
import Vue from 'vue';
import { DEFAULT_ERROR_MESSAGE_WITH_ENGINEERS, EModificationType } from '../globals';

export default Vue.extend({
  async created() {
    try {
      await this.fetchSensor();
      if (this.sensor && this.sensor.animalId) {
        await this.fetchAnimal();
      }
      if (this.sensor && this.sensor.vendorId) {
        await this.fetchVendor();
      }
    } catch (e) {
      if (e instanceof Error) {
        console.log('Show on interface: ', e);
      } else {
        console.log('Show on interface: ', DEFAULT_ERROR_MESSAGE_WITH_ENGINEERS);
      }
    }
  },
  data() {
    return {
      sensor: {
        animalId: '',
        sensorId: '',
        vendorId: ''
      },
      animal: {
        eid: ''
      },
      vendor: {
        name: ''
      }
    };
  },
  props: {
    id: {
      type: String,
      required: true,
    }
  },
  methods: {
    async fetchSensor() {
      try {
        this.sensor = await this.$store.dispatch(EModificationType.FETCH_SENSOR_BY_ID, {sensorId: this.id});
      } catch (e) {
        if (e instanceof Error) {
          console.log('Show on interface: ', e);
        } else {
          console.log('Show on interface: ', DEFAULT_ERROR_MESSAGE_WITH_ENGINEERS);
        }
      }
    },
    async fetchAnimal() {
      try {
        if (this.sensor && this.sensor.animalId) {
          this.animal = await this.$store.dispatch('FETCH_ANIMAL_BY_ID', {animalId: this.sensor.animalId});
        }
      } catch (e) {
        if (e instanceof Error) {
          console.log('Show on interface: ', e);
        } else {
          console.log('Show on interface: ', DEFAULT_ERROR_MESSAGE_WITH_ENGINEERS);
        }
      }
    },
    async fetchVendor() {
      try {
        if (this.sensor && this.sensor.vendorId) {
          this.vendor = await this.$store.dispatch('FETCH_VENDOR_BY_ID', {vendorId: this.sensor.vendorId});
        }
      } catch (e) {
        if (e instanceof Error) {
          console.log('Show on interface: ', e);
        } else {
          console.log('Show on interface: ', DEFAULT_ERROR_MESSAGE_WITH_ENGINEERS);
        }
      }
    }
  }
});
</script>

<style scoped>
.sensor-view-container {
  max-width: 600px;
  margin: 0 auto;
  padding: 20px;
}

.sensor-details {
  padding: 10px;
  border: 2px solid #666666;
  border-radius: 4px;
  background-color: #f9f9f9;
  margin-bottom: 20px;
}

.back-btn {
  padding: 10px 20px;
  font-size: 16px;
  background-color: #007bff;
  color: #fff;
  border: none;
  border-radius: 4px;
  cursor: pointer;
}
</style>
