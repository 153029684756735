import { render, staticRenderFns } from "./AddVendorView.vue?vue&type=template&id=3f3ae495&scoped=true"
import script from "./AddVendorView.vue?vue&type=script&lang=ts"
export * from "./AddVendorView.vue?vue&type=script&lang=ts"
import style0 from "./AddVendorView.vue?vue&type=style&index=0&id=3f3ae495&prod&scoped=true&lang=css"


/* normalize component */
import normalizer from "!../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "3f3ae495",
  null
  
)

export default component.exports