<template>
  <div class="health-alert-list-container">
    <h2>Health Alert List</h2>
    <div class="filters">
      <div class="filter-item">
        <label for="alert-type">Type:</label>
        <select id="alert-type" v-model="selectedType" @change="resetAndFetchHealthAlerts">
          <option value="ALL">All</option>
          <option value="TEMPERATURE">Temperature</option>
          <option value="PH">pH</option>
          <option value="HEART_RATE">Heart Rate</option>
          <option value="BLOOD_OXYGEN_LEVEL">Blood Oxygen Level</option>
        </select>
      </div>
      <div class="filter-item">
        <label for="start-date">Start Date:</label>
        <input type="date" id="start-date" v-model="startDate" @change="resetAndFetchHealthAlerts">
      </div>
      <div class="filter-item">
        <label for="end-date">End Date:</label>
        <input type="date" id="end-date" v-model="endDate" @change="resetAndFetchHealthAlerts">
      </div>
    </div>
    <div v-if="isLoading" class="loading-alerts">Loading...</div>
    <div v-else>
      <table v-if="healthAlerts.length" class="health-alert-table">
        <thead>
        <tr>
          <th>Message</th>
          <th>Animal EID</th>
          <th>Type</th>
          <th>Read</th>
          <th>Recorded Time</th>
          <th>Action</th>
        </tr>
        </thead>
        <tbody>
        <tr v-for="alert in healthAlerts" :key="alert.id" class="health-alert-item" @click="viewAnimal(alert.animalId.id, alert)">
          <td>{{ alert.message }}</td>
          <td>{{ alert.animalId.eid }}</td>
          <td>{{ alert.vitalType }}</td>
          <td>{{ alert.isRead ? 'Yes' : 'No' }}</td>
          <td>{{ formattedDate(alert.createdDate) }}</td>
          <td>
            <button @click.stop="markAsRead(alert)" class="toggle-read-btn">
              Mark as {{ alert.isRead ? 'Unread' : 'Read' }}
            </button>
          </td>
        </tr>
        </tbody>
      </table>
      <div v-else class="no-alerts">No health alerts available.</div>
      <div class="pagination">
        <button @click="previousPage" :disabled="currentPage === 1">Previous</button>
        <span>Page {{ currentPage }}</span>
        <button @click="nextPage" :disabled="currentPage * itemsPerPage >= totalDocumentCount">Next</button>
      </div>
    </div>
    <div v-if="isLoadingMore" class="loading-alerts">Loading more...</div>
  </div>
</template>

<script lang="ts">
import { DEFAULT_ERROR_MESSAGE_WITH_ENGINEERS, DEFAULT_LIST_LIMIT, EGettersType, EHealthAlertSortType, EModificationType, ESortOrderType } from '../globals';
import Vue from 'vue';
import { mapGetters } from 'vuex';

export default Vue.extend({
  data() {
    return {
      isLoading: false,
      isLoadingMore: false,
      currentPage: 1,
      totalDocumentCount: 0,
      itemsPerPage: DEFAULT_LIST_LIMIT,
      selectedType: 'ALL',
      startDate: '',
      endDate: ''
    };
  },
  created: function () {
    this.fetchHealthAlerts();
  },
  computed: {
    ...mapGetters([
      EGettersType.GET_HEALTH_ALERTS
    ])
  },
  methods: {
    resetAndFetchHealthAlerts() {
      this.$store.commit(EModificationType.SET_HEALTH_ALERTS, []);
      this.currentPage = 1;
      this.fetchHealthAlerts();
    },
    fetchHealthAlerts() {
      this.isLoading = true;
      this.$store.dispatch(EModificationType.FETCH_HEALTH_ALERTS, {
        sortOrder: ESortOrderType.DESC,
        sortBy: EHealthAlertSortType.DATE,
        limit: this.itemsPerPage,
        skip: (this.currentPage - 1) * this.itemsPerPage,
        isRead: 'FALSE',
        vitalType: this.selectedType !== 'ALL' ? this.selectedType : undefined,
        startDate: this.startDate ? new Date(this.startDate).toISOString() : undefined,
        endDate: this.endDate ? new Date(this.endDate).toISOString() : undefined
      }).then((count) => {
        this.totalDocumentCount = count;
        this.isLoading = false;
        this.isLoadingMore = false;
        if (this.healthAlerts.length === 0) {
          this.currentPage = 1;
        }
      }).catch(e => {
        this.isLoading = false;
        this.isLoadingMore = false;
        console.log('Show on interface: ', e.message || DEFAULT_ERROR_MESSAGE_WITH_ENGINEERS);
      });
    },
    previousPage() {
      if (this.currentPage > 1) {
        this.currentPage--;
        this.fetchHealthAlerts();
      }
    },
    nextPage() {
      if (this.currentPage * this.itemsPerPage < this.totalDocumentCount) {
        this.currentPage++;
        this.fetchHealthAlerts();
      }
    },
    async viewAnimal(animalId: string, alert: any) {
      try {
        // Mark the alert as read
        await this.$store.dispatch(EModificationType.MARK_HEALTH_ALERT_AS_READ, { healthAlertId: alert.id });

        // Navigate to the animal's details
        this.$router.push({ name: 'animalView', params: { id: animalId } });
      } catch (e: any) {
        console.error('Failed to mark alert as read:', e.message || DEFAULT_ERROR_MESSAGE_WITH_ENGINEERS);
      }
    },
    markAsRead(healthAlert: any) {
      try {
        this.$store.dispatch(EModificationType.MARK_HEALTH_ALERT_AS_READ, { healthAlertId: healthAlert.id });
        healthAlert.isRead = !healthAlert.isRead; // Toggle read status for immediate feedback
      } catch (e: any) {
        console.log('Failed to mark alert as read:', e.message || DEFAULT_ERROR_MESSAGE_WITH_ENGINEERS);
      }
    },
    formattedDate(date: string) {
      return new Date(date).toLocaleString(); // Formatting date and time to local string
    }
  }
});
</script>

<style scoped>
.health-alert-list-container {
  max-width: 1200px;
  margin: 0 auto;
  padding: 20px;
}

h2 {
  
  font-size: 24px;
  font-weight: bold;
  margin-bottom: 20px;
}

.filters {
  display: flex;
  justify-content: flex-start;
  margin-bottom: 20px;
}

.filter-item {
  display: flex;
  align-items: center;
  margin-right: 20px;
}

.filter-item label {
  margin-right: 10px;
}

select, input[type="date"] {
  padding: 8px;
  border-radius: 4px;
  border: 1px solid #ccc;
}

.health-alert-table {
  width: 100%;
  border-collapse: collapse;
}

.health-alert-table th,
.health-alert-table td {
  padding: 10px;
  text-align: left;
  border-bottom: 2px solid #666666;
}

.health-alert-table th {
  background-color: #f4f4f4;
}

.health-alert-item {
  cursor: pointer; /* Show hand cursor */
}

.health-alert-item:hover {
  background-color: #f9f9f9; /* Optional: Subtle background change on hover */
}

.toggle-read-btn {
  
  padding: 5px 10px;
  font-size: 14px;
  background-color: #007bff;
  color: #fff;
  border: none;
  border-radius: 4px;
  cursor: pointer;
  transition: background-color 0.3s ease;
}

.toggle-read-btn:hover {
  background-color: #0056b3;
}

.no-alerts, .loading-alerts {
  
  margin: 50px 0;
  font-size: 18px;
  text-align: center;
  color: #888;
}

.pagination {
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: 20px;
}

.pagination button {
  background-color: #007bff;
  color: white;
  border: none;
  padding: 8px 12px;
  border-radius: 4px;
  cursor: pointer;
  margin: 0 5px;
}

.pagination button:hover {
  background-color: #0056b3;
}

.pagination button:disabled {
  background-color: #cccccc;
  cursor: not-allowed;
}
</style>
