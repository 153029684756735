/**
 * Gets the access token
 */
export function getAccessToken(): string {
  const token = sessionStorage.getItem('token');
  return token ? token : "";
}

/**
 * Gets the refresh token
 */
export function getRefreshToken(): string {
  const token = sessionStorage.getItem('refreshToken');
  return token ? token : "";
}

/**
 * Sets the access token and isAuthed
 * @param token
 */
export function setAccessToken(token: string): void {
  sessionStorage.setItem('token', token);
  return;
}

/**
 * Sets the refresh token and isAuthed
 * @param token
 */
export function setRefreshToken(token: string): void {
  sessionStorage.setItem('refreshToken', token);
  return;
}

/**
 * Removes settings upon logout
 * TODO: Clear all cache on logout
 */
export function logUserOut(): void {
  sessionStorage.removeItem('token');
  sessionStorage.removeItem('refreshToken');
  localStorage.removeItem('userRole');
  return;
}

/**
 * Gets the user role
 */
export function getUserRole(): string {
  const userRole = localStorage.getItem('userRole');
  return userRole ? userRole : '';
}

/**
 * Sets the user role
 */
export function setUserRole(userRole: string): void {
  localStorage.setItem('userRole', userRole);
  return;
}