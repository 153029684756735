<template>
  <div>
    <h1>Reports</h1>
    <ReportList :id="id" />
  </div>
</template>

<script>
import ReportList from '../components/ReportList.vue';

export default {
  name: 'ReportsView',
  components: {
    ReportList,
  },
  props: {
    id: {
      type: String,
      required: true,
    },
  },
};
</script>